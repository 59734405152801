import React from 'react';
import { motion, cubicBezier } from 'framer-motion';

export const BreakBar = (props: any) => {
  const { text, top } = props;

  return (
    <motion.div
      id={'home'}
      initial={{ y: -200, opacity: 1 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
        delay: 0,
        duration: 0.3,
      }}
      className={`flex w-full ${top ? 'h-[64px]' : 'h-[164px]'} bg-[#2BA275] items-center justify-center z-30`}
    >
      <div
        className={`text-white px-8 lg:px-4 font-medium ${top ? 'tracking-[.3em] md:tracking-[.6em] uppercase text-sm lg:text-lg' : 'lg:text-2xl'}`}
      >
        {text}
      </div>
    </motion.div>
  );
};
