import React, { useState } from 'react';
import { InfoBar } from '../Components/Website/InfoBar';
import { NavBar } from '../Components/Website/NavBar';
import { NavBarMobile } from '../Components/Website/NavBarMobile';
import { Hero } from '../Components/Website/Hero';
import { Tabs } from '../Components/Website/Tabs';
import { Experts } from '../Components/Website/Experts';
import { Video } from '../Components/Website/Video';
import { Lifestyle } from '../Components/Website/Lifestyle';
import { HowItWorks } from '../Components/Website/HowItWorks';
import { BreakBar } from '../Components/Website/BreakBar';
import { Contact } from '../Components/Website/Contact';
import { About } from '../Components/Website/About';
import { Footer } from '../Components/Website/Footer';
import { AnimatePresence } from 'framer-motion';

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const palette = { mainGreen: '#2BA275' };

  return (
    <AnimatePresence>
      <div className={'flex w-full items-center flex-col justify-start bg-gray-50'}>
        <InfoBar text={'Medicinae Veritatis Peritus'} />
        <NavBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <NavBarMobile isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Hero palette={palette} />
        <Tabs />
        <Lifestyle />
        <Experts />
        <BreakBar text={'Next-Level Partnership: Attorneys and Medical Experts Fuse Talents with AI Support'} />
        {/*<Video />*/}
        <HowItWorks />
        <Contact />
        <About />
        <Footer />
      </div>
    </AnimatePresence>
  );
};

export default HomePage;
