import React from 'react';

export const TabsGrid = (props: any) => {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8 mt-4 lg:mt-12">
      <div className={'flex flex-col shadow-sm p-8 rounded-xl bg-white'}>
        <div className={'text-2xl font-bold text-left'}>World-class experts</div>
        <div className={'text-lg text-left lg:mt-4'}>
          Get paired with a top specialist expert to work on your legal case.
        </div>
      </div>
      <div className={'flex flex-col shadow-sm p-8 rounded-xl bg-white'}>
        <div className={'text-2xl font-bold text-left'}>Full Process Support</div>
        <div className={'text-lg text-left lg:mt-4'}>Our experts and our team supports you every step of the way.</div>
      </div>
      <div className={'flex flex-col shadow-sm p-8 rounded-xl bg-white'}>
        <div className={'text-2xl font-bold text-left'}>We move at your speed</div>
        <div className={'text-lg text-left lg:mt-4'}>
          Our experts use our proprietary AI models to sift through thousands of pages in minutes, isolating relevant
          information.
        </div>
      </div>
    </div>
  );
};
