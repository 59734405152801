import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';

const AppRoutes = () => {
  const [displayName, setDisplayName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');
  const [userClaim, setUserClaim] = React.useState('');

  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route
          path="/login"
          element={
            <LoginPage
              setDisplayName={setDisplayName}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              setUserClaim={setUserClaim}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
