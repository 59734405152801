import React from 'react';

export const HowItWorks = () => {
  return (
    <div className={'flex w-full justify-center bg-white'}>
      <div className={'flex flex-col items-center max-w-[1280px] py-20 px-8'}>
        <div className={'flex w-full text-5xl sm:text-6xl sm:justify-center font-thin tracking-wide'}>How it Works</div>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-5 mt-12">
          <div className={'flex flex-col'}>
            <div className={'flex text-2xl font-bold text-left'}>Create an Account</div>
            <div className={'flex text-left break-words mt-2 sm:mt-4'}>
              Begin by signing up and establishing your account.
            </div>
          </div>
          <div className={'flex flex-col'}>
            <div className={'flex text-2xl font-bold text-left'}>Request Expert Assistance</div>
            <div className={'flex text-left break-words mt-2 sm:mt-4'}>
              Share your specific requirements with us, and we will diligently identify and engage the most qualified
              expert for your case.
            </div>
          </div>
          <div className={'flex flex-col'}>
            <div className={'flex text-2xl font-bold text-left'}>Upload Medical Records</div>
            <div className={'flex text-left break-words mt-2 sm:mt-4'}>
              Easily provide your medical records through our secure platform.
            </div>
          </div>
          <div className={'flex flex-col'}>
            <div className={'flex text-2xl font-bold text-left'}>Centralized Expert Communications</div>
            <div className={'flex text-left break-words mt-2 sm:mt-4'}>
              Schedule phone conferences and efficiently identify expert availability for depositions and trial
              appearances.
            </div>
          </div>
          <div className={'flex flex-col'}>
            <div className={'flex text-2xl font-bold text-left'}>Effortless Billing</div>
            <div className={'flex text-left break-words mt-2 sm:mt-4'}>
              Rest assured, we handle all aspects of expert fee collections and billing, ensuring a seamless experience.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
