import React from 'react';

export const About = () => {
  return (
    <div id={'about'} className={'flex flex-col items-center justify-center w-full bg-gray-50'}>
      <div className={'flex items-center flex-col mt-20 w-full px-8 max-w-[1280px]'}>
        <div className={'text-5xl sm:text-6xl font-thin tracking-wide mb-12 text-left w-full'}>About MVP</div>
        <div className={'text-lg text-left'}>
          Medicinae Veritatis Peritus, MVP, (honesty in medical expertise) stands as a pivotal ally for law firms
          involved in litigation by offering a centralized platform for various tasks related to expert engagement,
          record management, and communication.
        </div>

        <div className={'grid grid-cols-1 md:grid-cols-2 gap-8 py-16'}>
          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Expert Discovery and Engagement</div>
            <div className={'text-md text-left mt-4'}>
              Facilitates the process of finding and engaging experts for legal cases. Acts as a one-stop solution for
              law firms seeking the right specialists for their cases.
            </div>
          </div>

          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Record Management and Review</div>
            <div className={'text-md text-left mt-4'}>
              Streamlines the management and review of extensive documentation related to legal cases.
            </div>
            <div className={'text-md text-left mt-4'}>
              Offers advanced software solutions to efficiently analyze and organize case documents.
            </div>
          </div>

          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Unified Communication Hub</div>
            <div className={'text-md text-left mt-4'}>
              Serves as a centralized communication hub for both legal professionals and medical experts.
            </div>
            <div className={'text-md text-left mt-4'}>
              Provides a single point of contact for seamless interaction between attorneys and specialists.
            </div>
          </div>

          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Scheduling and Coordination</div>
            <div className={'text-md text-left mt-4'}>
              Allows for the scheduling of appointments and coordination of activities between experts and attorneys.
            </div>
            <div className={'text-md text-left mt-4'}>
              Streamlines the process of arranging meetings, consultations, and other collaborative efforts.
            </div>
          </div>

          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Data Storage and Accessibility</div>
            <div className={'text-md text-left mt-4'}>
              Acts as a central repository for storing all relevant data related to legal cases.
            </div>
            <div className={'text-md text-left mt-4'}>
              Ensures easy and secure access to case information, expert opinions, and reports.
            </div>
          </div>

          <div className={'py-8 px-8 border-l-green-300 border-l-8 bg-white rounded-xl'}>
            <div className={'text-xl font-bold text-left'}>Comprehensive Source for Expert Opinions</div>
            <div className={'text-md text-left mt-4'}>
              Aggregates and stores all expert opinions and reports in one location.
            </div>
            <div className={'text-md text-left mt-4'}>
              Enables legal professionals to access a comprehensive source of information for case-related insights.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
