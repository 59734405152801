import React from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';

export const ContactForm = (props: any) => {
  const { setIsFormSubmitted, setIsFormSubmitting, isFormSubmitting } = props;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    setIsFormSubmitting(true);
    console.log(data);
    fetch('https://mvp-engine-dot-mvp-tool.uc.r.appspot.com/api/v1/contactus', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsFormSubmitted(true);
        setIsFormSubmitting(false);
      })
      .catch((error) => console.log('Error:', error));
  };
  //console.log(watch('example')); // you can watch individual input by pass the name of the input

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col'}>
      {/* register your input into the hook by invoking the "register" function */}
      <input
        defaultValue=""
        {...register('name', { required: true, minLength: 2 })}
        placeholder={'Full Name*'}
        className={`${errors.name ? 'border-pink-500' : 'border-white'} m-2 px-4 py-3 text-lg tracking-normal font-normal text-gray-900 bg-white rounded-3xl border-[4px] focus:border-[#2BA275]`}
      />

      {/* include validation with required or other standard HTML validation rules */}
      <input
        {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
        placeholder={'Email*'}
        className={`${errors.email ? 'border-pink-500' : 'border-white'} m-2 px-4 py-3 text-lg tracking-normal font-normal text-gray-900 bg-white rounded-3xl border-[4px] focus:border-[#2BA275]`}
      />
      <textarea
        {...register('comments')}
        placeholder={'Comments'}
        className={
          'm-2 px-4 py-2 text-lg tracking-normal font-normal text-gray-900 bg-white rounded-3xl min-h-[148px] border-[4px] border-white focus:border-[#2BA275]'
        }
      ></textarea>
      {isFormSubmitting ? (
        <div className={'flex w-full justify-center mt-11'}>
          <CircularProgress size={32} sx={{ color: 'black' }} />
        </div>
      ) : (
        <input
          type="submit"
          className={
            'm-2 px-4 py-3 text-lg font-bold text-white bg-[#2BA275] rounded-3xl cursor-pointer transition-all tracking-normal border-[4px] border-[#2BA275] hover:bg-black hover:border-black'
          }
        />
      )}
      {/* errors will return when field validation fails  */}
      <div className={'flex justify-center'}>
        {errors.name && (
          <div className={'text-sm text-green-900 font-normal tracking-normal'}>Full Name is required</div>
        )}
        {errors.name && errors.email && <div className={'text-sm text-green-900 font-normal mx-1'}>&</div>}
        {errors.email && (
          <div className={'text-sm text-green-900 font-normal tracking-normal'}>Valid Email is required</div>
        )}
      </div>
    </form>
  );
};
