import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { NavLinks } from './NavLinks';
import React from 'react';

export const NavBarMobile = (props: any) => {
  const { isMenuOpen, setIsMenuOpen } = props;

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <>
          <motion.div
            initial={{ y: -200, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
              delay: 0,
              duration: 0.3,
            }}
            exit={{ x: -200, opacity: 0 }}
            className={
              'absolute lg:hidden w-full h-[calc(100%-132px)] bg-white bg-opacity-100 z-10 items-center justify-center top-[132px]'
            }
          >
            <NavLinks view={'mobile'} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
