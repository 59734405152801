import React, { useEffect } from 'react';
export const NavLinks = (props: any) => {
  const { view, isMenuOpen, setIsMenuOpen } = props;
  const linkClasses = 'flex hover:text-[#2BA275] hover:transition-colors mx-4 my-2 transition-colors';

  const handleClick = () => {
    isMenuOpen && setIsMenuOpen(false);
  };

  return (
    <div
      className={`${view === 'desktop' ? 'hidden lg:flex flex-row text-xl' : 'text-6xl p-4 font-bold'} justify-center w-full text-black`}
    >
      <a href="#services" className={linkClasses} onClick={handleClick}>
        Services
      </a>
      <a href="#specialties" className={linkClasses} onClick={handleClick}>
        Specialties
      </a>
      <a href="#contact" className={linkClasses} onClick={handleClick}>
        Contact
      </a>
      <a href="#about" className={linkClasses} onClick={handleClick}>
        About
      </a>
      {isMenuOpen && (
        <a href="https://app.medverper.com/login" className={linkClasses}>
          Login
        </a>
      )}
    </div>
  );
};
