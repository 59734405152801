import React from 'react';
import { ContactForm } from './ContactForm';
import { motion, AnimatePresence, cubicBezier } from 'framer-motion';

export const Contact = () => {
  const [isformSubmitting, setIsFormSubmitting] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  return (
    <div
      id={'contact'}
      className={
        'flex flex-col items-center justify-center w-full bg-gradient-to-b from-green-100 to-green-300 h-[660px]'
      }
    >
      <div className={'text-5xl sm:text-6xl font-thin tracking-wide w-10/12 sm:w-1/2 xl:w-1/3 pt-20 pb-24'}>
        <div className={'text-5xl sm:text-6xl font-thin tracking-wide mb-12'}>Get in Touch</div>
        {!isFormSubmitted ? (
          <AnimatePresence>
            <motion.div
              key={'contact-form'}
              initial={{ y: 600 }}
              animate={{ y: 0 }}
              transition={{
                ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
                delay: 0,
                duration: 0.5,
              }}
              exit={{ y: -600 }}
            >
              <ContactForm
                setIsFormSubmitted={setIsFormSubmitted}
                setIsFormSubmitting={setIsFormSubmitting}
                isFormSubmitting={isformSubmitting}
                isFormSubmitted={isFormSubmitted}
              />
            </motion.div>
          </AnimatePresence>
        ) : (
          <motion.div
            key={'contact-form'}
            initial={{ y: 40 }}
            animate={{ y: 0 }}
            transition={{
              ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
              delay: 0,
              duration: 0.5,
            }}
            exit={{ y: -600 }}
          >
            <div className={'text-3xl text-black tracking-wide'}>Thank you for your submission!</div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
