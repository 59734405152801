import React from 'react';
import { motion, cubicBezier } from 'framer-motion';
import { ButtonMvp } from '../ButtonMvp';

export const Hero = (props: any) => {
  // const { palette } = props;

  return (
    <div
      className={
        'flex flex-col items-center w-full px-4 pt-4 lg:pt-0 lg:px-8 bg-gradient-to-t from-cyan-100 to-white] relative overflow-hidden'
      }
    >
      <motion.div
        initial={{ x: 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 0.8,
          duration: 0.3,
        }}
        className={'text-4xl md:text-6xl lg:text-6xl 2xl:text-7xl font-bold mx-8 max-w-[1280px]'}
      >
        <div className={'inline'}>Finding the right medical expert is a game changer... </div>
        <div className={`inline text-[#2BA275]`}>hire an MVP!</div>
      </motion.div>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 1,
          duration: 0.3,
        }}
        className={'flex text-xl w-2/3 lg:w-1/3 mt-8 md:mt-12'}
      >
        Next-level professionals connect your team to the best medical expert for your specific needs.
      </motion.div>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 1.2,
          duration: 0.3,
        }}
      >
        {/*<a className={'flex mt-8 lg:mt-12'} href={'#video'}>*/}
        {/*  <ButtonMvp action={'Watch Demo'} />*/}
        {/*</a>*/}
      </motion.div>
      <motion.div
        initial={{ scale: 1.2, y: 800, opacity: 0 }}
        animate={{ scale: 1, y: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 1.4,
          duration: 0.5,
        }}
        className={'flex items-center justify-center w-full bottom-0 mt-12 lg:mt-16 -mb-4'}
      >
        <img
          src="/images/hero-dash.png"
          alt="Dashboard"
          className={
            'w-full lg:w-3/4 rounded-t-[16px] md:rounded-t-[2em] lg:rounded-t-[3em] border-black border-[6px] md:border-[8px] lg:border-[12px]'
          }
        />
      </motion.div>
    </div>
  );
};
