import React from 'react';

export const Lifestyle = (props: any) => {
  return (
    <div
      className={'w-full min-h-[564px] bg-top bg-cover'}
      style={{ backgroundImage: 'url(/images/doctor-group-lifestyle.jpg)' }}
    ></div>
  );
};
