import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';

export const Footer = () => {
  return (
    <div className={`flex w-full h-[128px] bg-[#2BA275] items-center justify-center z-30 relative`}>
      <a
        className={
          'absolute bg-white p-6 rounded-3xl shadow-lg right-8 -top-10 cursor-pointer hover:-top-12 transition-all'
        }
        href={'#home'}
      >
        <ArrowUpwardIcon sx={{ fontSize: '32px' }} />
      </a>
      <div className={'text-white text-xs px-8 lg:px-4 font-medium'}>
        &copy; Copyright MVP 2024 - All Rights Reserved
      </div>
    </div>
  );
};
