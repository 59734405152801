import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
export const ButtonMvp = (props: any) => {
  const { action, secondary } = props;
  return (
    <div>
      <div
        className={`${secondary === true ? 'bg-[#2BA275] hover:bg-black' : 'bg-black hover:bg-[#2BA275]'} flex flex-row text-white transition-colors hover:text-white px-8 py-4 rounded-[32px] cursor-pointer`}
      >
        <div className={'pr-2 text-nowrap font-bold'}>{action}</div>
        <div
          className={'flex items-center justify-center'}
          onClick={() => {
            // props.setIsMenuOpen(true);
          }}
        >
          <ArrowRightAltIcon />
        </div>
      </div>
    </div>
  );
};
