import React from 'react';

export const Experts = (props: any) => {
  return (
    <div id={'specialties'} className={'flex flex-col items-center max-w-[1280px] py-20'}>
      <div className={'text-5xl sm:text-6xl font-thin tracking-wide'}>Our Experts</div>
      <div className="grid grid-cols-1 gap-8 sm:gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-12">
        <div
          className={'flex items-center flex-col py-24 lg:py-24 lg:px-16 rounded-xl bg-contain bg-no-repeat bg-center'}
          style={{ backgroundImage: 'url(/images/polygon.png)' }}
        >
          <div className={'text-2xl font-bold'}>Honest</div>
          <div className={'flex justify-center w-1/2 lg:w-full text-md lg:mt-4 break-words'}>
            Providing transparent and unbiased recommendations
          </div>
        </div>
        <div
          className={'flex items-center flex-col py-24 lg:py-24 lg:px-16 rounded-xl bg-contain bg-no-repeat bg-center'}
          style={{ backgroundImage: 'url(/images/polygon.png)' }}
        >
          <div className={'text-2xl font-bold'}>Efficient</div>
          <div className={'flex justify-center w-1/2 lg:w-full text-md lg:mt-4 break-words'}>
            Expediting expert connections and case resolution
          </div>
        </div>
        <div
          className={'flex items-center flex-col py-24 lg:py-24 lg:px-16 rounded-xl bg-contain bg-no-repeat bg-center'}
          style={{ backgroundImage: 'url(/images/polygon.png)' }}
        >
          <div className={'text-2xl font-bold'}>Available</div>
          <div className={'flex justify-center w-1/2 lg:w-full text-md lg:mt-4 break-words'}>
            Our platform offers 24/7 access and responsive customer support
          </div>
        </div>
        <div
          className={'flex items-center flex-col py-24 lg:py-24 lg:px-16 rounded-xl bg-contain bg-no-repeat bg-center'}
          style={{ backgroundImage: 'url(/images/polygon.png)' }}
        >
          <div className={'text-2xl font-bold'}>Thorough</div>
          <div className={'flex justify-center w-1/2 lg:w-full text-md lg:mt-4 break-words'}>
            Final report within one week of CME/IME
          </div>
        </div>
      </div>
    </div>
  );
};
