import React from 'react';
import { NavLinks } from './NavLinks';
import { ButtonMvp } from '../ButtonMvp';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { cubicBezier, motion } from 'framer-motion';

export const NavBar = (props: any) => {
  const { isMenuOpen, setIsMenuOpen } = props;

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={'flex w-full items-center p-4 lg:p-16 bg-gray-50'}>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 0.2,
          duration: 0.3,
        }}
        className={'flex w-2/3 lg:w-1/3 z-20'}
      >
        <img src="/images/logo-mvp.png" alt="Logo MVP" className={'w-[140px] lg:w-[180px]'} />
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
          delay: 0.4,
          duration: 0.3,
        }}
        className={'flex w-1/3'}
      >
        <NavLinks view={'desktop'} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </motion.div>
      <div className={'flex w-1/3 items-center justify-end z-20'}>
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
            delay: 0.6,
            duration: 0.3,
          }}
          className={'hidden lg:flex lg:items-center'}
        >
          {/*<a href="#" className={'mr-4 text-xl hover:text-[#2BA275] hover:transition-colors'}>*/}
          {/*  Sign Up*/}
          {/*</a>*/}
          <a href="https://app.medverper.com/login" target="_blank">
            <ButtonMvp action={'Login'} setIsMenuOpen={setIsMenuOpen} />
          </a>
        </motion.div>
        <div
          className={`${isMenuOpen && 'p-1 bg-white'} lg:hidden text-gray-700 cursor-pointer`}
          onClick={handleMenuClick}
        >
          {isMenuOpen ? <CloseIcon sx={{ fontSize: '48px' }} /> : <MenuIcon sx={{ fontSize: '48px' }} />}
        </div>
      </div>
    </div>
  );
};
